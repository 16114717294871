// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  margin: 20px;
  margin-left: 50px;
}

.technician-section {
  margin-bottom: 20px;
}

.ticket-row {
  margin-left: 18px;
  margin-bottom: 8px;
  font-size: 16px; /* Kleinere Schriftgröße */
}

.ticket-title {
  flex: 2 1;
  color: #333; /* Standardfarbe für den Titel */
}

.ticket-sla {
  flex: 1 1;
  color: blue; /* Orange für SLA */
  margin-left: 10px;
}

.otrs-link {
  flex: 1 1;
  color: gray; /* Blau für OTRS Link */
  text-decoration: none;
  margin-left: 10px;
  cursor: pointer;
}

.ticket-title:hover{
  color:black;
}

.otrs-link:hover {
  color: darkblue; /* Dunkleres Blau beim Hover */
}

h4 {
  margin-bottom: 10px;
  font-size: 40px; /* Kleinere Überschrift */
}
`, "",{"version":3,"sources":["webpack://./src/TechnicianList.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,eAAe,EAAE,0BAA0B;AAC7C;;AAEA;EACE,SAAO;EACP,WAAW,EAAE,gCAAgC;AAC/C;;AAEA;EACE,SAAO;EACP,WAAW,EAAE,mBAAmB;EAChC,iBAAiB;AACnB;;AAEA;EACE,SAAO;EACP,WAAW,EAAE,uBAAuB;EACpC,qBAAqB;EACrB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe,EAAE,8BAA8B;AACjD;;AAEA;EACE,mBAAmB;EACnB,eAAe,EAAE,yBAAyB;AAC5C","sourcesContent":[".container {\n  margin: 20px;\n  margin-left: 50px;\n}\n\n.technician-section {\n  margin-bottom: 20px;\n}\n\n.ticket-row {\n  margin-left: 18px;\n  margin-bottom: 8px;\n  font-size: 16px; /* Kleinere Schriftgröße */\n}\n\n.ticket-title {\n  flex: 2;\n  color: #333; /* Standardfarbe für den Titel */\n}\n\n.ticket-sla {\n  flex: 1;\n  color: blue; /* Orange für SLA */\n  margin-left: 10px;\n}\n\n.otrs-link {\n  flex: 1;\n  color: gray; /* Blau für OTRS Link */\n  text-decoration: none;\n  margin-left: 10px;\n  cursor: pointer;\n}\n\n.ticket-title:hover{\n  color:black;\n}\n\n.otrs-link:hover {\n  color: darkblue; /* Dunkleres Blau beim Hover */\n}\n\nh4 {\n  margin-bottom: 10px;\n  font-size: 40px; /* Kleinere Überschrift */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
