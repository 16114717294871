import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosConfig';  // Verwende die konfigurierte Axios-Instanz
import './TechnicianList.css'; // Füge eigene CSS hinzu

const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
    return result;
  }, {});
};

const TechnicianList = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await axiosInstance.get('/api/alltickets');
        setTickets(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Fehler beim Abrufen der Tickets:', error);
        setLoading(false);
      }
    };
    fetchTickets();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  // Filtere nur Tickets, deren Status nicht "closed successfully" ist
  const filteredTickets = tickets.filter(ticket => ticket.status !== 'closed successful');

  // Gruppiere Tickets nach Techniker
  const ticketsByTechnician = groupBy(filteredTickets, 'techniker');

  // Sortiere die Techniker und stelle sicher, dass "Admin OTRS" am Ende der Liste ist
  const sortedTechnicians = Object.keys(ticketsByTechnician).sort((a, b) => {
    if (a === "Admin OTRS") return 1;
    if (b === "Admin OTRS") return -1;
    return a.localeCompare(b);
  });

  return (
    <div className="container mt-4 technician-list">
      <h2>Techniker Tickets Übersicht</h2>
      {sortedTechnicians.map(tech => (
        <div key={tech} className="technician-section mb-4">
          <h4>{tech}</h4>
          {ticketsByTechnician[tech].map(ticket => (
            <div key={ticket.id} className="ticket-row">
              <a 
                href={`https://service.danubix.com/otrs/index.pl?Action=AgentTicketZoom;TicketID=${ticket.id}`}
                target="_blank"
                rel="noopener noreferrer"
                className="otrs-link"
              >
                <span className="ticket-title">{ticket.titel}</span>
              </a>
              <span className="ticket-sla">{ticket.sla}</span>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default TechnicianList;
