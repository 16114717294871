import React, { useState } from "react";
import axios from "axios";

const TransferProtocolForm = () => {
    const [formData, setFormData] = useState({
        ticketNumber: "",
        siebelReceiptNumber: "",
        deploymentDate: "",
        travelTime: "",
        workStartTime: "",
        workEndTime: "",
        userName: "",
        userId: "",
        address: "",
        postalCode: "",
        city: "",
        roomNumber: "",
        locationType: "",
        serialNumberNew: "",
        inventoryNumberNew: "",
        hostnameNew: "",
        meterReadingNew: "",
        macAddressNew: "",
        serialNumberOld: "",
        inventoryNumberOld: "",
        hostnameOld: "",
        meterReadingOld: "",
        hardwareCondition: "",
        assetType: "",
        otherDevices: "",
        cablesUsed: "",
        vosTechnician: "",
        technicianEmail: "",
        errorDescription: "",
        solution: "",
        customerSignature: null,
        technicianSignature: null,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormData({ ...formData, [name]: files[0] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData();
        Object.keys(formData).forEach((key) => {
            data.append(key, formData[key]);
        });

        try {
            const response = await axios.post("http://34.65.223.176:8080/api/post", data, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            alert("Form submitted successfully!");
        } catch (error) {
            console.error("Error submitting the form", error);
            alert("There was an error submitting the form.");
        }
    };

    return (
        <form onSubmit={handleSubmit} encType="multipart/form-data">
            <h2>ÜBERNAHMEPROTOKOLL POST VOS</h2>

            <input type="text" name="ticketNumber" placeholder="Ticketnummer" onChange={handleChange} />
            <input
                type="text"
                name="siebelReceiptNumber"
                placeholder="Siebel-Handscheinnummer"
                onChange={handleChange}
            />
            <input type="date" name="deploymentDate" onChange={handleChange} />
            <input type="text" name="travelTime" placeholder="Fahrzeit" onChange={handleChange} />
            <input type="time" name="workStartTime" placeholder="Arbeitszeit Beginn" onChange={handleChange} />
            <input type="time" name="workEndTime" placeholder="Arbeitszeit Ende" onChange={handleChange} />
            <input type="text" name="userName" placeholder="User Name" onChange={handleChange} />
            <input type="text" name="userId" placeholder="User ID/Org ID" onChange={handleChange} />
            <input type="text" name="address" placeholder="Adresse" onChange={handleChange} />
            <input type="text" name="postalCode" placeholder="PLZ" onChange={handleChange} />
            <input type="text" name="city" placeholder="Ort" onChange={handleChange} />
            <input type="text" name="roomNumber" placeholder="Raumnummer" onChange={handleChange} />
            <input type="text" name="locationType" placeholder="Standorttyp" onChange={handleChange} />
            <input
                type="text"
                name="serialNumberNew"
                placeholder="Seriennummer neu"
                onChange={handleChange}
            />
            <input
                type="text"
                name="inventoryNumberNew"
                placeholder="Inventarnummer neu"
                onChange={handleChange}
            />
            <input type="text" name="hostnameNew" placeholder="Hostname neu" onChange={handleChange} />
            <input type="text" name="meterReadingNew" placeholder="Zählerstand neu" onChange={handleChange} />
            <input type="text" name="macAddressNew" placeholder="Macadresse neu" onChange={handleChange} />
            <input type="text" name="serialNumberOld" placeholder="Seriennummer alt" onChange={handleChange} />
            <input
                type="text"
                name="inventoryNumberOld"
                placeholder="Inventarnummer alt"
                onChange={handleChange}
            />
            <input type="text" name="hostnameOld" placeholder="Hostname alt" onChange={handleChange} />
            <input type="text" name="meterReadingOld" placeholder="Zählerstand alt" onChange={handleChange} />
            <input type="text" name="hardwareCondition" placeholder="Zustand der Hardware" onChange={handleChange} />
            <input type="text" name="assetType" placeholder="Welches Asset" onChange={handleChange} />
            <input type="text" name="otherDevices" placeholder="Sonstige Geräte" onChange={handleChange} />
            <input type="text" name="cablesUsed" placeholder="Kabel verbraucht" onChange={handleChange} />
            <input type="text" name="vosTechnician" placeholder="VOS Techniker" onChange={handleChange} />
            <input
                type="email"
                name="technicianEmail"
                placeholder="Techniker E-Mail Adresse"
                onChange={handleChange}
            />
            <textarea name="errorDescription" placeholder="Fehlerbeschreibung" onChange={handleChange}></textarea>
            <textarea name="solution" placeholder="Lösung" onChange={handleChange}></textarea>

            <h3>Unterschriften</h3>
            <label>
                Unterschrift Kunde:
                <input
                    type="file"
                    name="customerSignature"
                    accept="image/*"
                    onChange={handleFileChange}
                />
            </label>
            <label>
                Unterschrift Techniker:
                <input
                    type="file"
                    name="technicianSignature"
                    accept="image/*"
                    onChange={handleFileChange}
                />
            </label>

            <button type="submit">Absenden</button>
        </form>
    );
};

export default TransferProtocolForm;
