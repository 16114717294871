import React, { useState } from 'react';

const TicketTitleGeneratorBilla= () => {
    const [ticketText, setTicketText] = useState("");
    const [title, setTitle] = useState("");

    // Function to extract the ticket ID
    const extractTicketId = (text) => {
        const ticketMatch = text.match(/Unsere Ticket-ID:\s*(\d+)/);
        return ticketMatch ? ticketMatch[1] : null;
    };

    // Function to extract the Billa location information
        const extractBillaInfo = (text) => {
    const storeMatch = text.match(
        /(Billa(?: Plus| Corso)?|Bipa|Sutterlüty)\s*(\d+)\s*\n(.*?)\n(.*?)(?:\n|$)/
    );
    if (storeMatch) {
        const type = storeMatch[1];
        const id = storeMatch[2];
        const street = storeMatch[3];
        const zipCity = storeMatch[4];
        return { type, id, street, zipCity };
    }
    return null;
};

    // Function to extract the player type
    const extractPlayerType = (text) => {
        const playerMatch = text.match(/Der\s+(.*?)\s+Player/);
        return playerMatch ? playerMatch[1].toUpperCase() : null;
    };

    // Function to extract SLA due date if provided
    const extractSlaDue = (text) => {
        const slaMatch = text.match(/SLA:\s*(.*)/);
        return slaMatch ? slaMatch[1] : null;
    };

    // Generate the title
    const generateTitle = () => {
        const ticketId = extractTicketId(ticketText);
        const billaInfo = extractBillaInfo(ticketText);
        const playerType = extractPlayerType(ticketText);
        const slaDue = extractSlaDue(ticketText);

        if (!ticketId || !billaInfo || !playerType) {
            setTitle("Fehler: Einige notwendige Informationen fehlen im Text.");
            return;
        }

        const { type, id, street, zipCity } = billaInfo;
        let generatedTitle = `[#${ticketId}] ${type} ${id} - ${zipCity} ${street} - Support - ${playerType} -`;

        if (slaDue) {
            generatedTitle += ` SLA: ${slaDue}`;
        }

        setTitle(generatedTitle);
    };

    return (
       <div style={{ padding: "20px", maxWidth: "1000px", position: "relative" }}>
            <h3>Billa Title Generator</h3>
            <div style={{ position: "absolute", top: "10px", right: "10px", border: "1px solid #ccc", padding: "10px", backgroundColor: "#f9f9f9" }}>
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                    <thead>
                        <tr>
                            <th colSpan="2" style={{ textAlign: "left", borderBottom: "1px solid #ddd", paddingBottom: "5px" }}>SLA</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ padding: "5px", borderBottom: "1px solid #ddd" }}>Mitarbeiter:</td>
                            <td style={{ padding: "5px", borderBottom: "1px solid #ddd" }}>96h (4 Tage)</td>
                        </tr>
                        <tr>
                            <td style={{ padding: "5px", borderBottom: "1px solid #ddd" }}>Kundenbereich:</td>
                            <td style={{ padding: "5px", borderBottom: "1px solid #ddd" }}>48h (2 Tage)</td>
                        </tr>
                        <tr>
                            <td style={{ padding: "5px" }}>STELE:</td>
                            <td style={{ padding: "5px" }}>36h (18 Uhr nächster Werktag)</td>
                        </tr>
                    </tbody>
                </table>
            </div>            <textarea
                rows="10"
                cols="50"
                value={ticketText}
                onChange={(e) => setTicketText(e.target.value)}
                placeholder="Ticket Text hier einfügen..."
                style={{ width: "100%", marginBottom: "10px" }}
            />
            <button onClick={generateTitle} style={{ marginBottom: "20px" }}>
                Titel generieren
            </button>
            {title && (
                <div>
                    <h4>Generierter Titel:</h4>
                    <p>{title}</p>
                </div>
            )}
        </div>
    );
};

export default TicketTitleGeneratorBilla; 
