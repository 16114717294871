import React, { useState } from 'react';

const TicketTitleGenerator = () => {
    const [ticketText, setTicketText] = useState("");
    const [title, setTitle] = useState("");

    // Funktion zum Extrahieren der INC-Nummer
    const extractIncNumber = (text) => {
        const incMatch = text.match(/(INC\d{7})/);
        return incMatch ? incMatch[1] : null;
    };

    // Funktion zum Extrahieren eines bestimmten Feldes
    const extractField = (text, fieldName) => {
        const regex = new RegExp(`${fieldName}:\\s*(.*)`);
        const match = text.match(regex);
        return match ? match[1].trim() : null;
    };

    // Funktion zum Generieren des Titels
    const generateTitle = () => {
        const incNumber = extractIncNumber(ticketText);
        if (!incNumber) {
            setTitle("Fehler: Keine gültige INC-Nummer gefunden.");
            return;
        }

        const standort = extractField(ticketText, "Standort Host");
        const modell = extractField(ticketText, "Modell-ID");
        const slaDue = extractField(ticketText, "SLA Due");

        // Prüfen, ob alle erforderlichen Felder gefunden wurden
        if (!standort || !modell || !slaDue) {
            setTitle("Fehler: Einige notwendige Felder konnten nicht gefunden werden.");
            return;
        }

        // Titel zusammensetzen und anzeigen
        setTitle(`${incNumber} - ${standort} - ${modell} - SLA: ${slaDue}`);
    };

    return (
        <div style={{ padding: "20px", maxWidth: "600px" }}>
            <h3>Ticket Title Generator</h3>
            <textarea
                rows="10"
                cols="50"
                value={ticketText}
                onChange={(e) => setTicketText(e.target.value)}
                placeholder="Ticket Text hier einfügen..."
                style={{ width: "100%", marginBottom: "10px" }}
            />
            <button onClick={generateTitle} style={{ marginBottom: "20px" }}>
                Titel generieren
            </button>
            {title && (
                <div>
                    <h4>Generierter Titel:</h4>
                    <p>{title}</p>
                </div>
            )}
        </div>
    );
};

export default TicketTitleGenerator;
