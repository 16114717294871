import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackToMenuButton = () => {
  const navigate = useNavigate();

  return (
    <button
      className="btn btn-light"
      style={{ position: 'absolute', top: '10px', right: '10px' }}
      onClick={() => navigate('/menu')}
    >
      X
    </button>
  );
};

export default BackToMenuButton;
