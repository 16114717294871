import React from 'react';
import './TicketPopup.css'; // CSS für das Popup, falls gewünscht

const TicketDetailsPopup = ({ ticket, onClose }) => {
  if (!ticket) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>X</button>
        <h3>Ticket Details</h3>
        <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
          {ticket.inhalt}
        </pre>
      </div>
    </div>
  );
};

export default TicketDetailsPopup;
