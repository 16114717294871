// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaflet-control-zoom {
  display: none;
}

.blinking-marker {
  animation: blink 1s infinite alternate;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}
`, "",{"version":3,"sources":["webpack://./src/TicketsMap.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,YAAY;EACd;AACF","sourcesContent":[".leaflet-control-zoom {\n  display: none;\n}\n\n.blinking-marker {\n  animation: blink 1s infinite alternate;\n}\n\n@keyframes blink {\n  0% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 0.7;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
